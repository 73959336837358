.react-draggable-overwrite {
    
    display: flex !important;
    position: relative !important;
    max-width: 100% !important;
    transform: none !important;
    align-items: center;
    justify-content: center;
    height: auto !important; 
  }
