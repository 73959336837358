.loading-container {
  width: 100%;
  background-color: #f0f0f0;
  overflow: hidden;
  position: relative;
}

.loading-line {
  width: 20%;
  height: 100%;
  position: absolute;
  animation: runLine infinite linear;
}

@keyframes runLine {
  0% {
    left: 0;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 100%;
  }
}
