@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient {
  background: linear-gradient(270deg, #d32f2f, #777777);
  background-size: 200% 200%;
  animation: gradient 4s ease-in-out infinite;
  -webkit-mask: url(../../images/logo_sml.svg) no-repeat center / contain;
  mask: url(../../images/logo_sml.svg) no-repeat center / contain;
  width: 90px;
  height: 90px;
}
