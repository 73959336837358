.slate-indent-1 ol,
.slate-indent-4 ol,
.slate-indent-7 ol,
.slate-indent-10 ol {
  list-style-type: decimal !important;
}

.slate-indent-2 ol,
.slate-indent-5 ol,
.slate-indent-8 ol,
.slate-indent-11 ol {
  list-style-type: lower-alpha !important;
}

.slate-indent-3 ol,
.slate-indent-6 ol,
.slate-indent-9 ol,
.slate-indent-12 ol {
  list-style-type: upper-roman !important;
}
